import { AfterViewInit, OnInit, Component, Input, Output, EventEmitter } from "@angular/core";
import { NgClass, NgIf } from "@angular/common";
import { ValidatingControlContainerComponent } from "../validating-control-container/validating-control-container.component";
import { RouterLink } from "@angular/router";
import { GqlError } from "src/app/services/graphql.module/model";
import { PlaceSearchResult } from "src/app/services/search.module/model/geo";
import { FormsModule } from "@angular/forms";

import { PbButtonComponent } from "../../../controls/pb-button/pb-button.component";
import { PbGeoInputComponent as PbGeoInputComponent_1 } from "../../../controls/pb-geo-input/pb-geo-input.component";
import { GeoSearchService } from "src/app/services/search.module/geosearch.service";


@Component({
    selector: 'search-across',
    templateUrl: './search-across.component.html',
    styleUrls: ['./search-across.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, ValidatingControlContainerComponent, FormsModule, PbGeoInputComponent_1, PbButtonComponent, RouterLink]
})
export class SearchAcrossComponent implements OnInit, AfterViewInit {
    @Input() heading: string;
    @Input() subHeading: string;
    @Input() placeholder: string;
    @Input() darkStyle: boolean;
    @Input() shouldTakeFullWidth: string = "true";
    @Input() doNotAutoUpdateLatestSearch: number;
    @Input() actionTrackingLabelSale: string = "Search For Sale";
    @Input() actionTrackingLabelRent: string = "Search For Rent";
    @Input() saleRentButtonTypes: string;


    @Output() searchTypeChanged: EventEmitter<string> = new EventEmitter<string>();

    searchArea:string;
    query:string;
    saleOrRent:string = "sale";
    isSaleTabSelected: boolean = true;
    errors: GqlError[];
    placeSearchResult: PlaceSearchResult;

    get isTabbed(): boolean {
        return this.saleRentButtonTypes === "both";
    }
    constructor(
       private locationsService: GeoSearchService
    ) {
        
    }

    ngOnInit() {
        

    }

    ngAfterViewInit(): void {
        if (this.saleRentButtonTypes && this.saleRentButtonTypes !== "both") {
            this.saleOrRent = this.saleRentButtonTypes;
        }
    }

    PerformButtonSearchAcross(saleOrRent: string) {

        if (!saleOrRent){
            saleOrRent = this.saleOrRent;
        }

        this.errors = new Array<GqlError>();


        if (!this.placeSearchResult){

            if (this.query){
            
                this.locationsService.PerformGeocoderSearch(this.query, true).then((data) => {
                    
                    if (!data || !data.geoSearchResult) {
                        this.errors = new Array<GqlError>();
                        this.errors.push({message: "Please enter/select a valid location", source: ""});                  
                    }
                    else{
                        this.placeSearchResult = data;
                        this.locationsService.NavigateToSearchResults(this.placeSearchResult, saleOrRent=='sale' ? 2 : 1);
                        return;
                    }
                });
            }
            else{
                this.errors.push({message: "Please enter/select a valid location", source: ""});
            }
        }
        else{
            this.locationsService.NavigateToSearchResults(this.placeSearchResult, saleOrRent=='sale' ? 2 : 1);
        }
    }


    OnClickedTab(type: string) {
        this.isSaleTabSelected = type === "sale";
        this.saleOrRent = this.isSaleTabSelected ? "sale" : "rent";
        this.searchTypeChanged.emit(this.saleOrRent);
    }

    onSearchAreaChanged(autoCompleteResult:any) {

        if (autoCompleteResult && autoCompleteResult.description){

            this.errors = new Array<GqlError>();
            if (!autoCompleteResult){
                this.errors.push({message: "Please enter/select a valid location", source: ""});
            }
        
            var placeSearchResult: PlaceSearchResult = {
                geoSearchResult: {
                    latitude: autoCompleteResult.latitude,
                    longitude: autoCompleteResult.longitude,
                    type: autoCompleteResult.types[0],
                    locationName: autoCompleteResult.description,
                    searchArea: autoCompleteResult.description,
                    localityId: autoCompleteResult.localityId
                },
                errors: new Array<GqlError>()
            };

            this.placeSearchResult = placeSearchResult;
            this.locationsService.NavigateToSearchResults(this.placeSearchResult, this.saleOrRent=='sale' ? 2 : 1);
        }
        else{

            //this is a text result
            this.locationsService.PerformGeocoderSearch(autoCompleteResult, true).then((data) => {
                                
                if (!data || !data.geoSearchResult) {
                    this.errors = new Array<GqlError>();
                    this.errors.push({message: "Please enter/select a valid location", source: ""});                  
                }
                else{
                    this.placeSearchResult = data;
                    this.locationsService.NavigateToSearchResults(this.placeSearchResult, this.saleOrRent=='sale' ? 2 : 1);
                    return;
                }
            });

        }
    }

    
}

