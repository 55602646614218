<div class="search-across" [ngClass]="{'dark-style':darkStyle, 'search-across-tabbed':isTabbed}">
    <div class="search-across-content" [ngClass]="{'full-width':shouldTakeFullWidth=='true'}">
        <div class="headings">
            <h1>{{heading}}</h1>
            <h2>{{subHeading}}</h2>
        </div>
        <div *ngIf="isTabbed" class="tabs">
            <button [attr.selected]="isSaleTabSelected?'':null" (click)="OnClickedTab('sale')">
                <label>For Sale</label>
                <span></span>
            </button>
            <button [attr.selected]="isSaleTabSelected?null:''" (click)="OnClickedTab('rent')">
                <label>To Rent</label>
                <span></span>
            </button>
        </div>
        <div class="search-form">
            <validating-control-container namespace="" [validationErrors]="errors">
                <form class="form-content" (ngSubmit)="PerformButtonSearchAcross('')">
                    <pb-geo-input caption="Location" placeholder="City, town or postcode" [(query)]="query" (SearchAreaChanged)="onSearchAreaChanged($event)" ></pb-geo-input>
                    <pb-button caption="Search" class="single" (click)="PerformButtonSearchAcross(isTabbed?'':saleRentButtonTypes)"></pb-button>
                </form>
            </validating-control-container>
        </div>
        <div *ngIf="isTabbed" class="adv-search-container">
            <a routerLink="/search">Advanced search</a>
        </div>
    </div>
</div>